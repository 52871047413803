import anime from 'animejs/lib/anime.es.js';

document.addEventListener('DOMContentLoaded', () => {
  anime({
    targets: '.promo-animation__hand',
    easing: 'linear',
    loop: true,
    endDelay: 1800,

    keyframes: [
      {opacity: 1, duration: 1000},
      {translateX: '-380%', translateY: '-400%', duration: 1000},
      {translateX: '-380%', translateY: '-400%', duration: 1000},
      {translateX: '530%', translateY: '-230%', duration: 1000},
      {opacity: 0, duration: 500},
    ],
  });

  anime({
    targets: '.promo-animation__sidebar-card-wrapper_drag',
    easing: 'linear',
    loop: true,
    delay: 2000,
    endDelay: 1800,

    keyframes: [
      {scale: 1.2, opacity: 1, duration: 300},
      {translateX: '0%', translateY: '0%', duration: 700},
      {translateX: '330%', translateY: '67%', duration: 1000},
      {opacity: 0, duration: 500},
    ],
  });

  anime({
    targets: '.promo-animation__line',
    easing: 'linear',
    loop: true,
    delay: 3200,
    endDelay: 1800,

    keyframes: [
      { opacity: 1, duration: 200},
      { opacity: 1, duration: 800},
      { opacity: 0, duration: 300},
    ],
  });

  anime({
    targets: '.promo-animation__insert',
    easing: 'linear',
    loop: true,
    delay: 4500,
    endDelay: 200,

    keyframes: [
      { opacity: 1, duration: 200},
      { opacity: 1, duration: 1100},
      { opacity: 0, duration: 300},
    ],
  });

  anime({
    targets: '.promo-animation__content-block_secondary',
    easing: 'linear',
    loop: true,
    delay: 4300,

    keyframes: [
      { translateY: '30%', duration: 500},
      { translateY: '30%', duration: 1000},
      { translateY: 0, duration: 500},
    ],
  });
});


