document.addEventListener('DOMContentLoaded', () => {
  var scene = document.getElementById('scene');
  var parallaxInstance = new Parallax(scene, {
    clipRelativeInput: true,
    relativeInput: true,
    hoverOnly: true,
    limitY: 0,
  });

  var downScene = document.getElementById('scene-down');
  var parallaxInstance = new Parallax(downScene, {
    // clipRelativeInput: true,
    // relativeInput: true,
    hoverOnly: true,
    limitY: 0,
  });
});

